import { render, staticRenderFns } from "./theme-0.vue?vue&type=template&id=028142dd"
import script from "./theme-0.vue?vue&type=script&lang=js"
export * from "./theme-0.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Theme0MobileNavBar: require('/app/components/Theme0/Mobile/NavBar.vue').default,Theme0Nav: require('/app/components/Theme0/Nav.vue').default,Theme0MobileFooter: require('/app/components/Theme0/Mobile/Footer.vue').default,Theme0Footer: require('/app/components/Theme0/Footer.vue').default,CommonsThemeMode: require('/app/components/Commons/ThemeMode.vue').default})
